import React from "react"
import Box from "@mui/material/Box"

const FlexRow = ({ sx = [], children }) => {
  return (
    <Box
      sx={[
        {
          "& > * + *": {
            marginLeft: { md: "1.5em" },
            marginTop: { xs: "2em", sm: "0em" },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      display="flex"
      pt={3}
      pb={3}
    >
      {children}
    </Box>
  )
}

export default FlexRow
