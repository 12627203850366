import React from "react"
import Layout from "./layout"
import OtherLayout from "./OtherLayout"

export const LayoutController = ({ children }) => {
  const pageRegex = /\/(landing|maintenance|404)\//
  if (pageRegex.test(window.location.pathname)) {
    return <OtherLayout>{children}</OtherLayout>
  }
  return <Layout>{children}</Layout>
}
