import React from "react"
import { Box, Link } from "@mui/material"

const NavBar = () => {
  return (
    <Box
      aria-label="navigation-bar"
      sx={{
        display: {
          xl: "flex",
          lg: "flex",
          md: "flex",
          sm: "none",
          xs: "none",
        },
        fontSize: "1.5rem",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        flex: "1 1 50%",
        maxHeight: "4em",
        // maxWidth: "50%",
        width: "50%",
        margin: "0 0.25em 0 0",
      }}
    >
      <Box
        sx={{
          border: "1px solid #fff",
          borderRadius: "5px",
        }}
      >
        <Link
          underline="always"
          sx={{
            "&:hover": {
              transition: "text-decoration 1s ease-out",
            },
            color: "primary.light",
            padding: "0.25em 0.5em",
          }}
          href="/"
          className="main-nav"
        >
          Home
        </Link>
        <Link
          underline="always"
          sx={{
            "&:hover": {
              transition: "text-decoration 1s ease-out",
            },
            color: "primary.light",
            padding: "0.25em 0.5em",
          }}
          href="/services/"
          className="main-nav"
        >
          Services
        </Link>
        <Link
          underline="always"
          sx={{
            "&:hover": {
              transition: "text-decoration 1s ease-out",
            },
            ml: "1em",
            color: "primary.light",
            padding: "0.25em 0.5em",
          }}
          href="/about/"
          className="main-nav"
        >
          About
        </Link>
        <Link
          underline="always"
          sx={{
            "&:hover": {
              transition: "text-decoration 1s ease-out",
            },
            color: "primary.light",
            padding: "0.25em 0.5em",
          }}
          href="/blog/"
          className="main-nav"
        >
          Blog
        </Link>
        <Link
          underline="always"
          sx={{
            "&:hover": {
              transition: "text-decoration 1s ease-out",
            },
            color: "primary.light",
            padding: "0.25em 0.5em",
          }}
          href="/contact"
          className="main-nav"
        >
          Contact
        </Link>
      </Box>
    </Box>
  )
}

export default NavBar
