import React from "react"
import Box from "@mui/material/Box"

const FlexColumn = ({ sx = [], children }) => {
  return (
    <Box
      mr={3}
      sx={[
        { display: "flex", flexDirection: "column" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  )
}

export default FlexColumn
