import * as React from "react"
import Hamburger from "@mui/icons-material/MenuSharp"
import { Box, Link, Popover } from "@mui/material"
import "./HamburgerMenu.css"

const HamburgerMenu = ({ toggleHamburger, setToggle, anchor, setAnchor }) => {
  const handleClick = () => {
    if (!toggleHamburger) {
      setToggle(e => (e = true))
      setAnchor(e => (e = `header`))
    } else {
      setToggle(e => (e = false))
    }
  }
  if (toggleHamburger) {
    return (
      <>
        <Hamburger
          sx={{
            fontSize: "3em",
            color: `#B0C4E5`,
            display: { xs: "block", sm: "block", md: "none" },
            ml: "0.5em",
          }}
          onClick={handleClick}
        />
        <Popover
          sx={{ top: "-5em" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={handleClick}
          onClose={handleClick}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              backgroundColor: "rgba(0,0,0,0.9)",
              position: "fixed",
              justifyContent: "center",
              right: "0%",
              top: "5rem",
              minWidth: "10em",
              width: "40%",
              height: "50%",
              color: "#fff",
              border: "3px solid blue",
            }}
          >
            <Link sx={{ color: "#fff" }} className="mobile-links" href="/">
              Home
            </Link>
            <Link
              sx={{ color: "#fff" }}
              className="mobile-links"
              href="/services"
            >
              Services
            </Link>
            <Link sx={{ color: "#fff" }} className="mobile-links" href="/about">
              About
            </Link>
            <Link sx={{ color: "#fff" }} className="mobile-links" href="/blog">
              Blog
            </Link>
            <Link
              sx={{ color: "#fff" }}
              className="mobile-links"
              href="/contact"
            >
              Contact
            </Link>
          </Box>
        </Popover>
      </>
    )
  } else {
    return (
      <>
        <Hamburger
          sx={{
            fontSize: "3em",
            color: `#B0C4E5`,
            display: { xs: "block", sm: "block", md: "none" },
            ml: "0",
          }}
          onClick={handleClick}
        />
      </>
    )
  }
}
export default HamburgerMenu
