import { createTheme } from "@mui/material/styles"

// More info on what you can update for the theme here: https://material-ui.com/customization/default-theme/#default-theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#000856",
      light: "#A9BED9",
      accentDark: "#2C3673",
      accentLight: "#8498BF",
      offWhite: "#ffffee",
    },
    secondary: {
      main: "#77869977",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          "&.MuiTypography-h1": {
            fontSize: "2.25rem",
            fontWeight: "500",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          },
        },
        h2: {
          "&.MuiTypography-h2": {
            fontSize: "1.75rem",
            fontWeight: "500",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          },
        },
        h3: {
          "&.MuiTypography-h3": {
            fontSize: "1.5rem",
            fontWeight: "500",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          },
        },
        h4: {
          "&.MuiTypography-h4": {
            fontSize: "1.25rem",
            fontWeight: "500",
            paddingTop: "0.5em",
            paddingBottom: "0.75em",
          },
        },
      },
    },
  },
})

export default theme
