/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Box from "@mui/material/Box"
import FlexRow from "components/containers/flexRow"
import FlexColumn from "components/containers/flexColumn"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import FSBLogo from "../../assets/fsb-member-logo-colour.png"
import CookiesPolicy from "../../assets/pdfs/CookiePolicy.pdf"
import PrivacyPolicy from "../../assets/pdfs/MyPrivacyNotice.pdf"

import Header from "../header"
import "./layout.css"

const Layout = ({ children }) => {
  const [toggleHamburger, setToggle] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        anchor={anchor}
        setAnchor={setAnchor}
        toggleHamburger={toggleHamburger}
        setToggle={setToggle}
        siteTitle={data.site.siteMetadata?.title || `Title`}
      ></Header>
      <Box
        sx={{
          color: "primary.main",
          backgroundColor: "primary.light",
        }}
      >
        {children}
      </Box>
      <footer
        style={{
          background: "#000",
          color: "#fff",
          marginTop: `0rem`,
          minHeight: "6em",
        }}
      >
        <Box sx={{ padding: "1em 2em" }}>
          <FlexRow
            sx={{
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: "flex-start",
              flexWrap: { xs: "wrap", md: "nowrap" },
              paddingBottom: 0,
            }}
          >
            <Typography
              sx={{
                flex: "0 1 100%",
                margin: "0 auto",
                paddingRight: "0.5em",
                paddingBottom: { xs: "0.5em" },
                textAlign: { xs: "center", md: "left" },
              }}
              variant="body1"
            >
              <Link
                sx={{
                  color: "white",
                }}
                href="mailto: contact@slickbluedragon.co.uk"
                underline="always"
              >
                Email Me Here
              </Link>
            </Typography>
            <FlexColumn
              sx={{
                alignItems: "flex-start",
                justifyContent: "space-between",
                margin: "0 0.25em 0.25em 2.5em",
                minWidth: "9em",
              }}
            >
              <Link sx={{ maxWidth: "10em" }} href="https://fsb.org.uk">
                <img
                  style={{
                    width: "50%",
                    minWidth: "5em",
                    maxWidth: "7em",
                    margin: "0.2em 0.2em 2em 0.2em",
                  }}
                  src={FSBLogo}
                  alt="Visit FSB's website"
                ></img>
              </Link>
              <Link underline="hover">
                <a
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  href={CookiesPolicy}
                >
                  Cookies Policy
                </a>
              </Link>
              <Link underline="hover">
                <a
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  href={PrivacyPolicy}
                >
                  Privacy Policy
                </a>
              </Link>
            </FlexColumn>
            <FlexColumn
              sx={{
                alignItems: { xs: "center", sm: "flex-start" },
                minWidth: "10em",
              }}
            >
              <Typography variant="body1">Slick Blue Dragon</Typography>
              <Typography variant="body1">Mailbox 340</Typography>
              <Typography variant="body1">4 Blenheim Court</Typography>
              <Typography variant="body1">Peppercorn Close</Typography>
              <Typography variant="body1">Peterborough</Typography>
              <Typography variant="body1">PE1 2DU</Typography>
            </FlexColumn>
          </FlexRow>
          <FlexRow
            sx={{
              justifyContent: "center",
              alignItems: "flex-end",
              paddingTop: "0.25em",
              paddingBottom: "0.25em",
            }}
          >
            <Typography sx={{}} variant="body1">
              Slick Blue Dragon ® {new Date().getFullYear()}
            </Typography>
          </FlexRow>
        </Box>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
